import Blok from "../../common/Blok";
import Title from "../../common/Title";
import Subtitle from "../../common/Subtitle";
import PS from "../../common/PS";
import Li from "../../common/Li";
import BOLD from "../../common/BOLD";
import Okno from "../../common/Okno";
import Section from "../../common/Section";

function Detailed() {
  return (
    <Section id="detailed" className="third-primary">
      <Title>Dane techniczne</Title>
      <Subtitle>Opis nieruchomości:</Subtitle>
      <Blok className="windows">
        <Okno title="Powierzchnie pomieszczeń parter:">
          <li>Salon z jadalnią – 24,71 m²</li>
          <li>Kuchnia – 11,31 m²</li>
          <li>Pokój (Gabinet) – 6,42 m²</li>
          <li>Wiatrołap – 4,05 m²</li>
          <li>Pomieszczenie Gospodarcze – 3,9 m²</li>
          <li>Łazienka – 3,34 m²</li>
          <li>Komunikacja – 2,48 m²</li>
        </Okno>
        <Okno title="Powierzchnie pomieszczeń piętro:">
          <li>Pokój 1 – 11,72 m²</li>
          <li>Pokój 2 – 11,63 m²</li>
          <li>Pokój 3 – 9,51 m²</li>
          <li>Łazienka – 8,84 m²</li>
          <li>Komunikacja – 4,42 m²</li>
          <li>Przestronne poddasze</li>
        </Okno>
        <Okno title="Powierzchnie działek:">
          <li>ulica Fiołkowa - 500 m²</li>
          <li>ulica Przytulna - 500 m²</li>
          <li>ulica Przytulna - dom z dostępem do lasku - 650 m²</li>
        </Okno>
        <Okno title="Opcje dodatkowe:">
          <li>
            Chodniczek od drogi do drzwi zewnętrznych, opaska na froncie - 4000
            zł
          </li>
          <li>
            Chodniczek od drogi do drzwi zewnętrznych, opaska wokół domu, taras
            5x4 ze schodami - 15.000 zł
          </li>
          <li>Odprowadzenie deszczówki - drenaż 25 mb pod ziemią - 5000 zł</li>
          <li>Wykonanie gładzi, jednokrotne malowanie - 17.000 zł</li>
        </Okno>
      </Blok>
      <Subtitle>Ważne informacje:</Subtitle>
      <Blok className="wide_window">
        <Li>
          Działki są z 3 stron ogrodzone – system panelowy z betonową podmurówką
        </Li>
        <Li>Okna PCV kolor antracyt, 3-szybowe</Li>
        <Li>Duże okno tarasowe - wyjście z salonu do ogrodu</Li>
        <Li>
          <PS />
          Drzwi zewnętrzne kolor antracyt z szybą w ramce inox
        </Li>

        <Li>
          <PS />
          Rolety zewnętrzne we wszystkich oknach
        </Li>
        <Li>
          <PS />
          Sterowanie rolet - podnoszone i opuszczane - elektrycznie
        </Li>
        <Li>
          <PS />
          Schody na poddasze
        </Li>
        <Li>Dwu funkcyjny piec gazowy (ciepła woda, ogrzewanie podłogowe)</Li>
        <Li>
          <PS />
          Instalacja C.O. - ogrzewanie podłogowe w całym domu (parter + piętro)
        </Li>
        <Li>
          <PS />
          Instalacja alarmowa - okablowanie (pod centarlę, panel sterowania,
          czujki, syreny alarmowe)
        </Li>
        <Li>
          <PS />
          Instalacja internetowa - okablowanie LAN - we wszystkich pięciu
          pokojach
        </Li>
        <Li>
          <PS />
          Instalacja TV - okablowanie - we wszystkich pięciu pokojach
        </Li>
        <Li>
          <PS />
          Ściany - ciepły bloczek komórkowy typu 500 + tynki firmy KNAUFF
        </Li>
        <Li>Dach - dachówka kolor antracyt firmy BRASS</Li>
      </Blok>
      <Subtitle>Informacje uzupełniające:</Subtitle>
      <Blok className="wide_window">
        <Li>
          Instalacja elektryczna, 3-fazowe podejście pod kuchenkę elektryczną,
          puszki przygotowane do białego montażu
        </Li>
        <Li>
          <PS />
          Duża ilość gniazdek elektrycznych w pokojach
        </Li>
        <Li>
          Okablowanie na zewnątrz do telewizji satelity (4 przewody) oraz
          dodatkowy przewód TV do odbioru telewizji naziemnej
        </Li>
        <Li>
          Punkty świetlne na zewnątrz domu: z przodu przy drzwiach wejściowych,
          z boku domu, z tylu domu przy tarasie
        </Li>
        <Li>
          <PS />
          Okablowanie zewnętrzne: 220V do gniazd (m.in. pod zasilanie bramy
          wjazdowej), 220V do oświetlenia
        </Li>

        <Li>
          <PS />
          Instalacja wideo-domofonowa z wyjściem okablowania do furtki
        </Li>
        <Li>
          <PS />
          Dodatkowa instalacja punktu poboru wody na zewnątrz wraz z
          podlicznikiem
        </Li>
        <Li>
          <PS />
          Instalacja umożliwiająca montaż stacji zmiękczania wody
        </Li>
        <Li>
          <PS />
          Instalacja pod czujniki temperatury we wszystkich 5 pokojach –
          możliwość podłączenia automatycznego sterowania ogrzewania podłogowego
        </Li>
        <Li>
          <PS />W 2 łazienkach możliwość zamontowania prysznica z odpływem
          liniowym
        </Li>

        <Li>Możliwość zainstalowania pralki na dwóch piętrach </Li>

        <Li>
          Możliwość zagospodarowania powierzchni pod schodami na piętro – od
          strony salonu lub od strony pomieszczenia gospodarczego
        </Li>

        <Li>
          W pełni funkcjonujące przyłącza: energetyczne, gazowe, wody i
          kanalizacji
        </Li>
      </Blok>
      <Subtitle>Sprzedaż / Kontakt :</Subtitle>
      <Blok className="wide_window">
        <Li check={false}>
          <BOLD>Promocyjna cena domu 499.000 zł </BOLD>
        </Li>
        <Li check={false}>
          <BOLD>Prowizja 0%</BOLD> - kupno bezpośrednio od dewelopera
        </Li>
        <Li check={false}>
          <BOLD>Kupujący nie płaci 2% podatku PCC</BOLD> – podatek opłaca
          deweloper
        </Li>
        <Li check={false}>
          <BOLD>Niskie koszty notarialne</BOLD>
        </Li>
        <Li check={false}>
          Pomagamy klientom uzyskać <BOLD>finansowanie</BOLD>
        </Li>
        <Li check={false}>
          <BOLD>Kontakt:</BOLD>
          <br />
          <BOLD>Maciej Wiczarski</BOLD>
          <br />
          tel. <BOLD>+48 504-211-211</BOLD> (dostępny również na WhatsApp)
          <br />
          Adres e-mail: <BOLD>mwpatron@wp.pl</BOLD>
        </Li>
      </Blok>
    </Section>
  );
}

export default Detailed;
