// @ts-nocheck
import {useEffect} from "react";
import NavIndent from "./common/NavIndent";
import ZrealizowaneLista from "./sites/zrealizowane/ZrealizowaneLista";
import {useLocation} from "react-router-dom";

function ZrealizowaneProjekty() {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main>
      <NavIndent />
      {/* <ZrealizowaneImg /> */}
      <ZrealizowaneLista />
      {/* <Linarczyk bg="WHITE" color="BLACK" arrows="BLACK" hover="GOLD" /> */}
      {/* <MalyRudnik bg="WHITE" color="BLACK" arrows="BLACK" hover="GOLD" /> */}
      {/* <Australia bg="WHITE" color="BLACK" arrows="BLACK" hover="GOLD" /> */}
    </main>
  );
}

export default ZrealizowaneProjekty;
