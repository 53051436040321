import Blok from "../../common/Blok";
import Section from "../../common/Section";
import Title from "../../common/Title";

function Description() {
  return (
    <Section className="third-primary">
      <Title>Opis</Title>
      <Blok className="text">
        <p>
          <span className="bolder">Kameralne Osiedle w Węgrowie</span> to
          idealna propozycja dla osób szukających przestronnego, oszczędnego
          domu w spokojnej i malowniczej okolicy. Ze względu na sąsiedztwo
          natury, terenów zielonych i lasów, jest to idealna propozycja dla osób
          kochających bliskość przyrody oraz rekreacje na świeżym powietrzu.
          Dodatkowo w odległości 7 km znajduje się Jezioro Rudnickie z plażą
          miejską, placem zabaw, plażą Delfin, parkiem rozrywki Mega-Park.
        </p>
      </Blok>
      <Blok className="text">
        <p>
          <span className="bolder">
            Domy posiadają bardzo dobrą infrastrukturę
          </span>
          . Są zlokalizowane zaledwie 2 km od Geotermii i Szpitala Miejskiego w
          Grudziądzu, skąd jest doskonały dojazd do Węgrowa drogą asfaltową. W
          bliskim otoczeniu znajduje się szkoła podstawowa, boisko sportowe,
          sklep spożywczy, gminna świetlica, siłownia na świeżym powietrzu,
          supermarket Dino. Dla osób korzystających z autostrady również
          lokalizacja domów jest korzystna - łatwy i szybki dojazd do bramek
          autostradowych.
        </p>
      </Blok>
      <Blok className="text">
        <p>
          <span className="bolder">
            Domy są w stanie deweloperskim zamkniętym
          </span>
          . Posiadają powierzchnię 103 m2 z opcją zagospodarowania poddasza.
          Charakteryzują się ergonomicznym podziałem, wysoką energooszczędnością
          i bardzo dobrym wykorzystaniem całej powierzchni domu. Na parterze
          znajduje się obszerny salon z kuchnią, łazienka z prysznicem, duże
          pomieszczenie gospodarcze, wiatrołap oraz dodatkowy pokój dla gości
          lub z przeznaczeniem na biuro. Piętro zawiera 3 sypialnie, dużą
          łazienkę oraz hol skąd jest wejście na przestronne poddasze. Dodatkową{" "}
          <span className="bolder">zaletą każdego domu</span> są m.in.
          zewnętrzne rolety sterowane elektrycznie, ogrzewanie podłogowe,
          instalacja alarmowa, instalacja internetowa i TV w każdym pokoju.
        </p>
      </Blok>
      <Blok className="text">
        <p>
          <span className="bolder">Doświadczenie i satysfakcja klientów</span>.
          Dotychczas wybudowaliśmy kilkadziesiąt domów w różnych konfiguracjach,
          a wszystkie nasze realizacje są wysoko oceniane za jakość wykonania i
          wygodę codziennego użytkowania. W roku 2022 zakończyliśmy sprzedaż
          ośmiu identycznych domów modelu „Europa” w Rokietnicy pod Poznaniem.
          Klienci są niezwykle zadowoleni z niskich kosztów eksploatacyjnych,
          zwłaszcza w kontekście ogrzewania oraz z dobrze przemyślanego układu
          pomieszczeń, który zapewnia optymalny komfort. Dodatkowo podkreślają
          fakt, iż domy posiadają aż pięć pokoi, w tym jeden na parterze,
          idealny do wykorzystania jako biuro, pokój gościnny lub garderobę oraz
          dwie łazienki.
        </p>
      </Blok>
    </Section>
  );
}

export default Description;
