import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {siteContentSettings} from "../settings/settings";
import {GalleryImage, GalleryState} from "../types/Reducer";

// Tworzenie listy JSX ze ścieżkami do obrazu
function importAllSpecified(
  r: __WebpackModuleApi.RequireContext
): GalleryImage[] {
  const widths = siteContentSettings.general.srcsetWidths;
  const images: GalleryImage[] = [];

  r.keys().forEach((key) => {
    const regEx = key.match(
      new RegExp(`([\\w\\s\\-()]+)-(${widths.join("|")})w\\.webp$`)
    );

    if (regEx) {
      const name = regEx[1];
      const width = regEx[2];
      const src = r(key);

      const existingImg = images.find((image) => image.name === name);
      if (existingImg) {
        existingImg.widths.push({src, width});
      } else {
        images.push({name, widths: [{src, width}]});
      }
    }
  });

  return images;
}

const mainSiteImages = importAllSpecified(
  require.context("../images/gallery", false, /\.webp$/)
);
const linarczykImages = importAllSpecified(
  require.context("../images/zrealizowane_projekty/linarczyk", false, /\.webp$/)
);
const malyRudnikImages = importAllSpecified(
  require.context(
    "../images/zrealizowane_projekty/mały_rudnik",
    false,
    /\.webp$/
  )
);
const australiaImages = importAllSpecified(
  require.context(
    "../images/zrealizowane_projekty/wegrowo_australia",
    false,
    /\.webp$/
  )
);

const finishedImages = importAllSpecified(
  require.context("../images/zrealizowane_projekty/finished", false, /\.webp$/)
);

const offerImages = importAllSpecified(
  require.context("../images/offer", false, /\.webp$/)
);

const mainBgImages = importAllSpecified(
  require.context("../images/main_bg", false, /\.webp$/)
);

const initialState: GalleryState = {
  main_site: {images: mainSiteImages, selectedIndex: 0},
  linarczyk: {images: linarczykImages, selectedIndex: 0},
  mały_rudnik: {images: malyRudnikImages, selectedIndex: 0},
  wegrowo_australia: {images: australiaImages, selectedIndex: 0},
  finished: {images: finishedImages, selectedIndex: 0},
  offer: {images: offerImages, selectedIndex: 0},
  main_bg: {images: mainBgImages, selectedIndex: 0},
};

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    setImgIndex: (
      state: GalleryState,
      action: PayloadAction<{galleryName: string; index: string | number}>
    ): void => {
      const {galleryName, index} = action.payload;
      const {images, selectedIndex} = state[galleryName];
      console.log(images);
      console.log(selectedIndex);

      if (index === "prev") {
        if (selectedIndex <= 0) {
          state[galleryName].selectedIndex = 0;
        } else {
          state[galleryName].selectedIndex = selectedIndex - 1;
        }
      }

      if (index === "next") {
        if (selectedIndex >= images.length - 1) {
          state[galleryName].selectedIndex = images.length - 1;
        } else {
          state[galleryName].selectedIndex = selectedIndex + 1;
        }
      }

      if (typeof index === "number") {
        state[galleryName].selectedIndex = index;
      }

      return;
    },
  },
});

export const galleryActions = gallerySlice.actions;
export default gallerySlice.reducer;
