import React from "react";
import Blok from "../../common/Blok.js";
import Title from "../../common/Title.js";
import Section from "../../common/Section.js";
import Img from "../../common/Img.tsx";
import {createSizesStr} from "../../../store/utils/functions.ts";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/types/Reducer";

function Offer() {
  const gallery = useSelector((state: RootState) => state.gallery["offer"]);

  return (
    <Section id="offer" className="offer third-primary">
      <Title>Oferta</Title>
      <Img
        src={gallery.images[0]}
        sizes={createSizesStr([
          {max: 768, width: "80vw"},
          {max: 1024, width: "70vw"},
          {max: "", width: "80vw"},
        ])}
        alt="Dom"
      />
      <Blok className="text">
        <p>
          Zapraszamy do zakupu nowoczesnych, energooszczędnych domów
          bliźniaczych o powierzchni 103 m², usytuowanych w malowniczej
          miejscowości Węgrowo, 2 km od Grudziądza. Domy wykonane są w{" "}
          <span className="bolder">podwyższonym standardzie</span>. Zostały
          zaprojektowane z myślą o funkcjonalności i komforcie, oferując
          optymalne rozmieszczenie pomieszczeń oraz praktyczne rozwiązania. Stan
          deweloperski zamknięty, obejmujący pełne podłączenie do wszystkich
          mediów (prąd, woda, gaz, kanalizacja, ogrzewanie), pozwala na
          natychmiastowe przystąpienie do wykończenia wnętrz zgodnie z
          indywidualnymi preferencjami. Nasza oferta łączy wysoką jakość
          wykonania z korzystną ceną, zapewniając przyszłym mieszkańcom
          maksymalną satysfakcję.
        </p>
      </Blok>
    </Section>
  );
}

export default Offer;
