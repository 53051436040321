// @ts-nocheck
import Blok from "../../common/Blok.js";
import BOLD from "../../common/BOLD.js";
import Li from "../../common/Li.js";
import Section from "../../common/Section.js";
import Title from "../../common/Title.js";
import Img from "../../common/Img.tsx";
import {createSizesStr} from "../../../store/utils/functions.ts";
import {useSelector} from "react-redux";

function ZrealizowaneLista(props) {
  const gallery = useSelector((state: RootState) => state.gallery["finished"]);

  return (
    <Section id="zrealizowaneLista" className="third-primary">
      <Title>Zrealizowane Projekty</Title>

      <Img
        src={gallery.images[0]}
        sizes={createSizesStr([
          {max: 768, width: "80vw"},
          {max: 1024, width: "70vw"},
          {max: "", width: "80vw"},
        ])}
        alt="Dom"
      />
      <hr />
      <hr />
      {/* <Title></Title> */}

      <Blok className="wide_window">
        <Li>
          <BOLD>Rokietnica koło Poznania</BOLD> - ul. Spokojna - 4 domy 98 m2 w
          zabudowie bliźniaczej
        </Li>
        <Li>
          <BOLD>Mały Rudnik koło Grudziądza</BOLD> - 4 domy 154 m2 w zabudowie
          bliźniaczej
        </Li>
        <Li>
          <BOLD>Rokietnica koło Poznania</BOLD> - 4 domy 102 m2 w zabudowie
          bliźniaczej
        </Li>
        <Li>
          <BOLD>Linarczyk</BOLD> - 2 domy 154 m2 w zabudowie bliźniaczej
        </Li>
        <Li>
          <BOLD>Węgrowo</BOLD> - ul. Przytulna - 2 domy 136 m2 wolnostojące w
          zabudowie parterowej
        </Li>
        <Li>
          <BOLD>Rokietnica koło Poznania</BOLD> - ul. Spokojna - 8 domów 102 m2
          w zabudowie bliźniaczej
        </Li>
        <Li>
          <BOLD>Węgrowo</BOLD> - ul. Fiołkowa - 4 domy 103 m2 w zabudowie
          bliźniaczej
        </Li>
        <Li>
          <BOLD>Węgrowo</BOLD> - ul. Przytulna - 8 domów 103 m2 w zabudowie
          bliźniaczej (obecnie)
        </Li>
      </Blok>
    </Section>
  );
}

export default ZrealizowaneLista;
