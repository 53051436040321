import styled from "styled-components";

const FooterStyled = styled.footer`
  background-color: var(--nav-bg);
  color: var(--WHITE);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0px;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* padding: 1.5rem 4rem; */
  }

  & .teaser {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (max-width: 1023px) {
      display: none;
    }

    & h1,
    h2 {
      display: flex;
      margin: 0px;
      margin-top: 10px;
      margin-left: 0px;
      font-size: 2.5em;
      font-weight: bolder;

      @media (min-width: 768px) and (max-width: 1024px) {
      }
      @media (min-width: 480px) and (max-width: 768px) {
      }
      @media (max-width: 480px) {
        font-size: 2em;
      }
    }

    & h2 {
      margin-left: 2vw;
    }
  }

  & .arrow {
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 5em;

    @media (min-width: 1024px) {
      display: flex;
    }
  }

  & .contact {
    display: flex;
    flex-direction: column;
    margin-right: 3vw;

    & .contact-data {
      display: flex;
      align-items: center;

      & .contact-icon {
        font-size: 2rem;
        font-weight: lighter;
        margin: 10px;

        & * {
          display: flex;
          align-items: center;
        }
      }

      & p {
        font-size: 1.5em;
        font-weight: bolder;
      }
    }
  }

  & .contact-data {
  }
`;

export default FooterStyled;
