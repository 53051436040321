import styled from "styled-components";

const BlokStyled = styled.div`
  padding-bottom: 1.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.text {
    display: flex;
    justify-content: center;
    width: 80vw;
    align-items: center;

    & p {
      text-align: justify;
      text-justify: inter-word;

      font-size: 1.4rem;

      @media (min-width: 1900px) {
        font-size: 1.6rem;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 1.3rem;
      }
      @media (min-width: 480px) and (max-width: 768px) {
        font-size: 1.2rem;
      }
      @media (max-width: 480px) {
        text-align: left;
        font-size: 1.1rem;
      }

      & .bolder {
        font-weight: bolder;
      }
    }
  }

  &.wide_window {
    border: var(--BLACK) solid 1px;
    width: 80vw;
    padding: 2.5rem 5vw;
    padding-bottom: 1.5rem;

    @media (min-width: 768px) and (max-width: 1024px) {
    }
    @media (min-width: 480px) and (max-width: 768px) {
      width: 85vw;
    }
    @media (max-width: 480px) {
      width: 90vw;
    }

    & h3 {
      font-size: 1.5rem;
      font-weight: bolder;
    }

    & li {
      list-style-type: none;
      font-size: 1.3rem;
      margin: 0.6vw 0;
      margin-left: 0.5vw;

      @media (min-width: 1900px) {
        font-size: 1.4rem;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 1.2rem;
      }
      @media (min-width: 480px) and (max-width: 768px) {
        font-size: 1.1rem;
      }
      @media (max-width: 480px) {
        font-size: 1.1rem;
      }
    }

    & p {
      font-size: 1.3rem;
      font-weight: bolder;
      margin: 0px;
      margin-bottom: 1.5rem;

      @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 1.25rem;
      }
      @media (min-width: 480px) and (max-width: 768px) {
        margin-bottom: 1rem;
      }
      @media (max-width: 480px) {
        margin-bottom: 0.75rem;
      }
    }

    & .promocja {
      color: var(--promocja-color);
      font-size: 1.7rem;
      font-weight: bolder;
      text-align: center;
    }

    & a {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 1.4rem;
      font-family: raleway, sans-serif;
      padding: 0.9rem 1.5rem;
      background: none;
      border: var(--BLACK) solid 1px;
      font-weight: bold;
      transition: color 0.35s ease 0s;
      text-decoration: none;
      text-align: center;

      color: inherit;

      margin-bottom: 1.5rem;

      @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 1.25rem;
      }
      @media (min-width: 480px) and (max-width: 768px) {
        margin-bottom: 1rem;
      }
      @media (max-width: 480px) {
        margin-bottom: 0.75rem;
      }

      &:visited {
        color: inherit;
      }
      & .icon {
        margin-right: 0.5em;
        font-size: 1.4rem;
      }
    }
  }

  &.windows {
    margin: 0rem 4.5vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 5vw;
    row-gap: 4rem;

    @media (min-width: 768px) and (max-width: 1024px) {
      margin: 2rem 0vw;
      row-gap: 2.5rem;
    }
    @media (min-width: 480px) and (max-width: 768px) {
      margin: 1.5rem 0vw;
      row-gap: 1.5rem;
    }
    @media (max-width: 480px) {
      margin: 1rem 0vw;
      row-gap: 0.8rem;
    }
  }

  &.no_border {
    border: none;
  }
`;

export default BlokStyled;
