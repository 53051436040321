import NavStyled from "./styles/NavStyled";
import salePrompt from "../store/images/other/red-offer.png";
import logoImg from "../store/images/logo/wiczarski_logo.png";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {viewActions} from "../store/reducer/slice-view";
import {RxHamburgerMenu} from "react-icons/rx";
import HamburgerMenu from "./HamburgerMenu";

function Nav() {
  const navRef = useRef(null);
  const dispatch = useDispatch();
  const currentSite = useSelector((state) => state.currentSite);
  const siteContent = useSelector((state) => state.siteContent)[currentSite];
  const isMenuOpened = useSelector((state) => state.view.isMenuOpened);
  const [logoError, setLogoError] = useState(false);
  const onLogoError = () => {
    setLogoError(true);
  };

  useEffect(() => {
    const navHeigth = navRef.current.getBoundingClientRect().height;
    dispatch(viewActions.setNavHeigth(navHeigth));
  }, [dispatch]);

  const logoImgEl = (
    <div className="logo-img-container">
      <img src={logoImg} alt="WICZARSKI" onError={onLogoError} />
      <h1>DEWELOPER</h1>
    </div>
  );

  const logoAltEl = (
    <div id="logoAlt">
      <h1>DOMY</h1>
      <h1>WICZARSKIEGO</h1>
    </div>
  );
  const logoEl = (
    <a id="logo" href="#" className="logo-container">
      {logoError ? logoAltEl : logoImgEl}
    </a>
  );
  const offerBtn = <a href="#offer">OFERTA</a>;
  const discountBtn = (
    <a href="#discount" className="sale-prompt">
      <h1 className="hidden">PROMOCJA</h1>
      <img src={salePrompt} alt="promImg" />
      <h1 className="text_on_top">PROMOCJA</h1>
    </a>
  );
  const galleryBtn = <a href="#gallery">GALERIA</a>;
  const technicalsBtn = (
    <a href="#detailed">
      DANE
      <br />
      TECHNICZNE
    </a>
  );
  const actual_offerBtn = (
    <NavLink to="/">
      AKTUALNA
      <br />
      OFERTA
    </NavLink>
  );
  const completed_projectsBtn = (
    <NavLink to="/zrealizowane_projekty/">
      ZREALIZOWANE
      <br />
      PROJEKTY
    </NavLink>
  );
  const contactBtn = (
    <a href="#contact" className="contact">
      KONTAKT
    </a>
  );

  const toggleMenu = () => {
    dispatch(viewActions.toggleIsMenuOpened());
  };

  return (
    <nav id="nav">
      <NavStyled ref={navRef}>
        {siteContent.logo && logoEl}
        <div className="buttonsBar">
          {siteContent.offer && offerBtn}
          {siteContent.discount && discountBtn}
          {siteContent.gallery && galleryBtn}
          {siteContent.technicals && technicalsBtn}
          {siteContent.actual_offer && actual_offerBtn}
          {siteContent.completed_projects && completed_projectsBtn}
          {siteContent.contact && contactBtn}
        </div>
        <div className="navHamburger" onClick={toggleMenu}>
          <RxHamburgerMenu className="icon" />
        </div>
        {isMenuOpened && <HamburgerMenu />}
      </NavStyled>
    </nav>
  );
}

export default Nav;
