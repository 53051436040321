import Galeria from "./sites/main_site/Galeria";
import DaneTechniczne from "./sites/main_site/DaneTechniczne";
import Oferta from "./sites/main_site/Oferta.tsx";
import Promocja from "./sites/main_site/Promocja";
import Opis from "./sites/main_site/Opis";
import Intro from "./sites/main_site/Intro";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";

function MainSite() {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <main>
        <Intro></Intro>
        <Oferta></Oferta>
        <Promocja></Promocja>
        <Opis></Opis>
        <Galeria></Galeria>
        <DaneTechniczne></DaneTechniczne>
      </main>
    </>
  );
}

export default MainSite;
